.breadcrumbs {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-family: ClashGrotesk;
}

.breadcrumbs a {
  color: var(--clr-text-lightest-final);
  font-weight: 500;
  text-decoration: none;
  /* text-decoration-color: var(--clr-link); */
}

.breadcrumbs > *:not(:first-child) {
  display: inline-flex;
  align-items: center;
}

.breadcrumbs > *:not(:first-child):before {
  content: '';
  display: inline-block;
  flex-shrink: 0;
  left: -17px;
  background: var(--chevron-svg);

  font-size: 16px;
  width: 1em;
  aspect-ratio: 1 / 1;
  background-size: 1em;
  top: calc(50% - 12px);
  opacity: 0.3;
  color: var(--clr-text-lightest);
  margin-right: 0.5rem;
}

.headerLogo,
.headerLogoSmall {
  padding: 1rem 0;
  background: rgb(var(--rgb-gray-0));
  height: 3.75rem;
  font-size: 0;
}

.headerLogoSmall {
  height: unset;
}

.headerLogo a {
  margin: 0.3rem 0;
}
