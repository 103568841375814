.sidebarContainer {
  --spacing: 2rem;
  height: 100vh;
  background: linear-gradient(
    175deg,
    rgb(var(--rgb-gray-1)) 0%,
    rgba(var(--rgb-gray-0), 0.7) 85%
  );
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  z-index: 6;
  transition: transform 0.15s ease;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.sidebarContainerCollapsible {
  transform: translateX(-100%);
  position: fixed;
}

.sidebarContainerVisible {
  transform: translateX(0);
  transition: transform 0.4s cubic-bezier(1, 0.04, 0.21, 0.93);
}

.sidebarMainContent {
  overflow-y: auto;
  flex-grow: 1;
  border-right: 1px solid var(--clr-border-1);
  padding-bottom: 1rem;
}

.sidebarLogo,
.sidebarLogoSmall {
  padding: 1rem 2rem;
  background: rgb(var(--rgb-gray-0));
  height: 3.75rem;
}

.sidebarLogoSmall {
  padding: 1.4rem 0;
  display: none;
}

.sidebarLogo a {
  margin: 0.3rem 0;
}

.sidebarFooter {
  padding: 0rem 1rem 1rem;
  border-right: 1px solid var(--clr-border-1);
}

.sidebarSection {
  margin-top: 1rem;
  padding-left: var(--spacing);
}

.sidebarPageItemText {
  display: inline-block;
  margin-left: 0.5rem;
  flex: 1;
}

.sidebarPageItem {
  padding: 0.5rem 0;
  margin: 0.2rem 0;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--clr-sidebar-link);
  font-size: var(--font-size-1);
  font-family: 'ClashGrotesk';
  font-weight: 500;
  transition: color 0.125s ease;
}

.onboardingCheckmark {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 2px solid var(--clr-green);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.onboardingCheckmark[data-checked='true'] {
  background: var(--clr-green);
}

.onboardingCheckmark[data-checked='true']:after {
  content: '';
  position: absolute;
}

.sidebarPageItem svg {
  position: relative;
  top: -1px;
  color: var(--clr-sidebar-icon);
  transition: 0.2s ease;
}

.sidebarPageItemActive {
  position: relative;
  z-index: 1;
}

.sidebarPageItemActive,
.sidebarPageItem:hover,
.sidebarPageItem:active,
.sidebarPageItem:focus {
  color: var(--clr-sidebar-link-hover);
}

.sidebarPageItemActive svg,
.sidebarPageItem:hover svg {
  color: var(--clr-sidebar-icon-hover);
}
.sidebarPageItem:hover svg {
  transform: translateX(-3px);
}

.sidebarPageItemActive:before {
  content: '';
  position: absolute;
  left: -1rem;
  top: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    95.67deg,
    rgb(var(--rgb-sidebar-highlight)) 0%,
    rgba(var(--rgb-sidebar-highlight), 0) 92.54%
  );
  box-shadow: inset 1px 1px 2px rgba(var(--rgb-white), 0.1);
  border-radius: 32px 0px 0px 32px;
  z-index: -1;
}

.sidebarSectionHeading {
  margin-left: calc(var(--spacing) * -1);
  margin-bottom: 0.75rem;
  padding: 0.25rem 0 0.25rem var(--spacing);
  background: var(--clr-section-heading);
  opacity: 0.5;
  font-family: 'IBM Plex Mono';
  font-size: var(--font-size-0);
  font-weight: 600;
  text-transform: uppercase;
  color: var(--clr-sidebar-link);
}

.sidebarCopyright {
  font-size: var(--font-size-0);
  font-family: 'ClashGrotesk';
  font-weight: 500;
  color: var(--clr-sidebar-icon);
}

.sidebarSocialIcon {
  line-height: 34px;
  display: inline-block;
  margin: 0;
  color: #8f98a2;
  background: linear-gradient(
    95.41deg,
    rgba(45, 55, 64, 0.6) 0%,
    rgba(39, 45, 52, 0.6) 101.76%
  );
  box-shadow: inset 1px 1px 2px rgba(255, 255, 255, 0.1);
  border-radius: 40px;
  height: 36px;
  width: 36px;
  text-align: center;
  transition: color 0.125s ease;
}

.sidebarSocialIcon:hover,
.sidebarSocialIcon:active,
.sidebarSocialIcon:focus {
  color: white;
}

.sidebarSocialLinks {
  padding-left: 0.5rem;
}

.sidebarPageItemTag {
  color: var(--clr-brand);
  font-family: 'IBM Plex Mono';
  font-size: 0.75rem;
  text-transform: uppercase;
  background: rgba(var(--rgb-brand), 0.15);
  border-radius: 0.25rem;
  padding: 0.1rem 0.4rem;
  box-shadow: inset 1px 1px 2px rgba(var(--rgb-brand), 0.2);
  margin-left: 0.5rem;
  margin-right: 1rem;
}

/* Height adjustments */
@media screen and (max-height: 806px) {
  .sidebasidebarFooter {
    box-shadow: 0 -10px 40px rgba(0, 0, 0, 0.25);
  }
}

/* Width adjustments */
@media screen and (max-width: 1340px) and (min-width: 600px) {
  .sidebarContainer {
    width: 5.5rem;
  }
  .sidebarPageItem {
    display: block;
    width: 2.25rem;
    height: 2.25rem;
    padding: 0;
    margin: 0.2rem auto;
    line-height: 2.25rem;
  }

  .sidebarPageItem:active .sidebarPageItemText,
  .sidebarPageItem:focus .sidebarPageItemText,
  .sidebarPageItem:hover .sidebarPageItemText {
    visibility: visible;
    opacity: 1;
    transform: translateX(0rem);
  }

  .sidebarPageItemText {
    position: absolute;
    white-space: nowrap;
    text-align: left;
    padding: 0 1rem;
    margin-left: 1rem;
    background: linear-gradient(
      95deg,
      rgba(var(--rgba-tile-top)) 0%,
      rgba(var(--rgba-tile-bottom)) 100%
    );
    box-shadow: inset 1px 1px 3px rgba(var(--rgb-white), 0.1);
    border-radius: 0.5rem;
    -webkit-backdrop-filter: blur(16px);
    backdrop-filter: blur(16px);
    opacity: 0;
    visibility: hidden;
    transform: translateX(-1rem);
    pointer-events: none;
    transition-property: opacity, transform, visibility;
    transition-duration: 0.125s;
    transition-timing-function: ease;
  }

  .sidebarPageItemActive {
    position: static;
    background: linear-gradient(
      95.67deg,
      rgb(var(--rgb-sidebar-highlight)) 0%,
      rgba(var(--rgb-sidebar-highlight), 0) 92.54%
    );
    box-shadow: inset 1px 1px 2px rgba(var(--rgb-white), 0.1);
    border-radius: 32px;
  }

  .sidebarPageItemActive:before {
    display: none;
  }

  .sidebarFooter {
    display: none;
  }

  .sidebarSection {
    padding: 0;
    text-align: center;
  }

  .sidebarPageItem div {
    justify-content: center;
  }

  .sidebarLogoSmall {
    display: block;
    text-align: center;
  }
  .sidebarLogo {
    display: none;
  }

  .sidebarPageItemActive svg,
  .sidebarPageItem:hover svg {
    color: var(--clr-sidebar-link-hover);
  }

  .sidebarPageItemTag {
    font-size: 0;
    padding: 0;
    margin: 0;
    background: rgba(var(--rgb-brand), 0.5);
    vertical-align: middle;
    transform: translate3d(0.25rem, 0.875rem, 0rem);
    position: absolute;
    height: 0.5rem;
    width: 0.5rem;
  }
}
/* 
@media (max-width: 600px) {
  .sidebarContainer {
    width: auto;
  }
} */
