.widget {
  /* background: linear-gradient(133.9deg, #2c353e -34.69%, #20262c 100.93%); */
  /* box-shadow: 0px 30px 60px rgba(0, 0, 0, 0.25), */
  /* inset 1px 1px 3px rgba(255, 255, 255, 0.1); */
  position: relative;
  top: -0.7rem; /* 0.7rem = header padding */
}

.bg {
  position: absolute;
  z-index: -1;
  top: -24px;
  pointer-events: none;
  filter: var(--streak-widget-shadow);
}

.widgetText {
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.widgetMain {
  width: 103px;
  height: 78px;
  position: relative;
  display: block;
  transition: 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

.widgetMain:hover {
  transform: scale(1.15);
}

.widgetShineBig,
.widgetShineSmall {
  position: absolute;
  top: -8px;
  left: -8px;
  height: 48px;
  width: 48px;
  background: var(--streak-shine-clr);
  z-index: -1;
  border-radius: 50%;
  filter: blur(32px);
}

.widgetShineBig {
  height: 96px;
  width: 96px;
  top: -32px;
  left: -32px;
  opacity: 0.5;
}

.bar {
  --width: 8px;
  --separation: 0.5rem;
  --small-height: 24px;

  position: absolute;
  width: var(--width);
  height: 40px;
  top: 0px;
  background: var(--streak-bar-clr);
  box-shadow: inset 1px 1px 3px rgba(255, 255, 255, 0.1);
  border-radius: 0px 0px 8px 8px;
  transform-origin: top;
  transition: 0.3s ease 0.15s;
}
.bar:nth-last-child(1) {
  left: calc(100% + var(--separation) * 2 + var(--width));
  height: var(--small-height);
}
.bar:nth-last-child(2) {
  left: calc(100% + var(--separation));
}

.bar:nth-last-child(3) {
  right: calc(100% + var(--separation));
}

.bar:nth-last-child(4) {
  height: var(--small-height);
  right: calc(100% + var(--separation) * 2 + var(--width));
}
.widgetMain:hover ~ .bar {
  --separation: 0.8rem;
  transform: scale(0.9);
}
.rays {
  /* width: 200%; */
  /* height: 200px; */
  position: absolute;
  top: 0;
  /* radial gradient with repeating-conic-gradient */
  /* background: repeating-conic-gradient(
    from 0deg,
    #39434c 0deg 30deg,
    #2c353e 30deg 60deg
  ); */
  content: var(--streak-rays-image-url);
  left: 50%;
  transform: translateX(-50%) translateY(-50%) rotate(0deg);
  z-index: -2;
  pointer-events: none;
  transform-origin: center;
  animation: rays 60s linear infinite;
  opacity: 0.075;
  /* mix-blend-mode: screen; */
  top: 50%;
  width: 350px;
  aspect-ratio: 1 / 1;
  transition: 0.5s ease 0.1s;
}
@keyframes rays {
  to {
    transform: translateX(-50%) translateY(-50%) rotate(360deg);
  }
}

.widget:has(.widgetMain:hover) .rays {
  opacity: 0.15;
}

@media (max-width: 600px) {
  .rays {
    width: 240px;
  }
}

.reminderPopup {
  opacity: 0;
  position: absolute;
  top: 110px;
  width: 320px;
  left: 50%;
  transform: translate(-50%, -40px);
  visibility: hidden;
  transition: all 0.25s ease;
  transition-property: opacity transform;
}

.reminderPopup:before {
  height: 40px;
  width: 100%;
  inset: 0;
  top: -40px;
  content: '';
  position: absolute;
}

.widget:hover .reminderPopup {
  opacity: 1;
  visibility: visible;
  transform: translate(-50%, 0);
}
