:root {
  --font-size-0: 0.875rem;
  --font-size-1: 1rem;
  --font-size-2: 1.125rem;
  --font-size-3: 1.375rem;
  --font-size-3-5: 1.5rem;
  --font-size-3-75: 1.75rem;
  --font-size-4: 2rem;
  --font-size-5: 3rem;
  --font-size-6: 4rem;

  --clr-bg: #07080b;
  --clr-sidebar-bg: #171d23;
  --clr-scrollbar: rgba(255, 255, 255, 0.2);

  --clr-highlight: rgba(255, 255, 255, 0.1);

  --clr-header-bg: #0f1117;
  --clr-footer-bg: #0f1117;
  --clr-banner-bg: #0f1117;
  --clr-centered-box-bg: #0f1117;

  --clr-inner-header: #21272d;
  --clr-inner-header-highlight: #2a313a;
  --clr-tile-header: #212a32;

  --clr-box-bg: #13181c;
  --clr-box-bg-darker: #121518;
  --clr-editor-bg: #07080b;

  --clr-colorlist: #000;

  --clr-border: #27313a;
  --clr-border-brighter: #323f4a;
  --clr-target-tile-bg: #27313a;
  --clr-target-tile-bg-lighter: #323e48;
  --clr-active-filter: #27313a;
  --clr-dropdown-bg: #27313a;
  --clr-dropdown-btn: #43515e;

  --clr-btn: #323f4a;
  --clr-btn-hover: #43515e;
  --clr-btn-primary: #0060ca;
  --clr-btn-primary-hover: #0050b0;
  --clr-btn-danger: #923c3c;
  --clr-btn-danger-hover: #7f2f2f;
  --clr-btn-success: #1e5222;
  --clr-btn-success-hover: #16421a;
  --clr-btn-text: #fff;
  --clr-btn-secondary-text: #fff;

  --clr-input: #0f1117;

  --clr-brand: #ffdf00;

  --clr-loader: #fff;

  --clr-link: #fff;
  --clr-heading: #eff5fb;
  --clr-text: #cbd1e1;
  --clr-tab-bg-selected: #323f4a;
  --clr-header-text: #cbd1e1;
  --clr-text-light: #a0b3c6;
  /* TODO: change to lighter */
  --clr-text-lightest: #91a7be;
  --clr-text-lightest-final: #6b7b8e;
  --clr-text-hire: #fff;

  --clr-green: #616f39;
  --clr-green-bright: #a1e435;
  --clr-green-lighter: #1e5222;
  --clr-red: #f83c3b;

  --gold-medal-img: url(/images/icons/gold.svg);
  --silver-medal-img: url(/images/icons/silver.svg);
  --bronze-medal-img: url(/images/icons/bronze.svg);

  --clr-gold-crown: #ffdf00;
  --clr-silver-crown: #c0c0c0;
  --clr-bronze-crown: #cd7f32;

  --star-img: url(/images/icons/star.svg);

  --table-header-bg: rgba(0, 0, 0, 0.7);
  --table-row-bg: rgba(0, 0, 0, 0.15);

  /* NEW - V2 */
  --rgb-black: 0, 0, 0;
  --rgb-white: 255, 255, 255;

  --rgb-gray-0: 24, 29, 35;
  --rgb-gray-1: 29, 35, 43;

  --rgb-sidebar-highlight: 53, 63, 73;
  --clr-section-heading: #101217;

  --clr-sidebar-link: #95a7bc;
  --clr-sidebar-icon: #4c5663;
  --clr-sidebar-link-hover: #fff;
  --clr-sidebar-icon-hover: #7c8c9e;

  --rgb-tile-top: 45, 55, 64;
  --rgb-tile-bottom: 39, 45, 52;

  --rgb-tile-active-top: 45, 55, 64;
  --rgb-tile-active-bottom: 39, 45, 52;

  --rgba-tile-top: 45, 55, 64, 0.5;
  --rgba-tile-bottom: 39, 45, 52, 0.5;

  --clr-border-1: #20262e;
  --rgb-brand: 255, 223, 0;

  --panel-bg: linear-gradient(
    94.5deg,
    rgba(29, 35, 43, 0.75) 0.19%,
    rgba(24, 29, 35, 0.5625) 100%
  );

  --glow-shadow: 0 0 30px rgba(var(--rgb-brand), 0.3),
    0 0 0 2px var(--clr-brand), 0px 10px 50px rgba(0, 0, 0, 0.5);

  --panel-shadow: 0 0 30px rgba(var(--rgb-brand), 0),
    0px 20px 50px rgba(0, 0, 0, 0.2), inset 1px 1px 3px rgba(255, 255, 255, 0.1);

  --tile-overlay-color: #13161b;

  --pill-bg: #2d363f;
  --pill-bg-key: rgb(255 255 255 / 5%);
  --pill-bg-danger: rgb(163, 72, 61);
  --pill-bg-warning: #ffdf0022;
  --pill-bg-success: #4a6302;
  --pill-text-key: rgb(255 255 255 / 40%);
  --pill-text-key-hover: rgb(255 255 255 / 50%);
  --pill-text-danger: #fff;
  --pill-text-warning: #ffdf00;
  --pill-text-success: #fff;
  --pill-shadow-key: rgba(255 255 255 / 10%);

  --hard-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1),
    0px 24px 50px rgba(0, 0, 0, 0.15),
    inset 1px 1px 3px rgba(255, 255, 255, 0.1);

  --gold-shadow-color: #262100;
  --silver-shadow-color: #1d1d1d;
  --bronze-shadow-color: #1f1308;

  --target-border-color: #111217;

  --podium-rgb-0: 29, 35, 43;
  --podium-rgb-1: 24, 29, 35;
  --podium-text-color: #080a0c;

  --streak-widget-clr-1: #2c353e;
  --streak-widget-clr-2: #20262c;
  --streak-shine-clr: #3c4750;
  --streak-bar-clr: #39434c;
  --streak-widget-shadow: drop-shadow(0px 10px 30px rgba(0, 0, 0, 0.75));
  --streak-rays-image-url: url('/images/streak-rays.png');

  /* Images */
  --logo-url: url('/images/logo-new.svg');
  --logo-small-url: url('/images/logo-new-glyph.svg');
  --chevron-svg: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path fill="white" d="M18 12c0 .535-.188.982-.652 1.42l-8.354 8.089c-.331.327-.74.491-1.226.491C6.796 22 6 21.225 6 20.264c0-.491.199-.928.564-1.288l7.281-6.987-7.281-6.976A1.753 1.753 0 0 1 6 3.736C6 2.786 6.796 2 7.768 2c.486 0 .895.164 1.226.491l8.354 8.09c.453.426.652.873.652 1.419Z"/></svg>');

  --z-index-modal: 11;
  --z-index-header: 6;

  --target-aspect-ratio: 4 / 3;

  --font-mono: 'IBM Plex Mono', monospace;
}
