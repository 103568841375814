.dialog {
  --app-bg: var(--gray1);

  --gray1: hsl(0, 0%, 8.5%);
  --gray2: hsl(0, 0%, 11%);
  --gray3: hsl(0, 0%, 13.6%);
  --gray4: hsl(0, 0%, 15.8%);
  --gray5: hsl(0, 0%, 17.9%);
  --gray6: hsl(0, 0%, 20.5%);
  --gray7: hsl(0, 0%, 24.3%);
  --gray8: hsl(0, 0%, 31.2%);
  --gray9: hsl(0, 0%, 43.9%);
  --gray10: hsl(0, 0%, 49.4%);
  --gray11: hsl(0, 0%, 62.8%);
  --gray12: hsl(0, 0%, 93%);

  --grayA1: hsla(0, 0%, 100%, 0);
  --grayA2: hsla(0, 0%, 100%, 0.026);
  --grayA3: hsla(0, 0%, 100%, 0.056);
  --grayA4: hsla(0, 0%, 100%, 0.077);
  --grayA5: hsla(0, 0%, 100%, 0.103);
  --grayA6: hsla(0, 0%, 100%, 0.129);
  --grayA7: hsla(0, 0%, 100%, 0.172);
  --grayA8: hsla(0, 0%, 100%, 0.249);
  --grayA9: hsla(0, 0%, 100%, 0.386);
  --grayA10: hsla(0, 0%, 100%, 0.446);
  --grayA11: hsla(0, 0%, 100%, 0.592);
  --grayA12: hsla(0, 0%, 100%, 0.923);
}

.dialog {
  max-width: 640px;
  width: 100%;
  padding: 8px;
  background: #ffffff;
  border-radius: 12px;
  overflow: hidden;
  border: 1px solid var(--gray6);
  box-shadow: var(--cmdk-shadow);
  transition: transform 100ms ease;
  background: #171d23;
  pointer-events: auto;
}
.dialog [cmdk-input] {
  font-family: var(--font-sans);
  border: none;
  width: 100%;
  padding: 8px 8px 16px 8px;
  outline: none;
  background: var(--bg);
  color: var(--gray12);
  border-bottom: 1px solid var(--gray6);
  margin-bottom: 16px;
  border-radius: 0;
}
.dialog [cmdk-input]::placeholder {
  color: var(--gray9);
}
.dialog [cmdk-vercel-badge] {
  height: 20px;
  background: var(--grayA3);
  display: inline-flex;
  align-items: center;
  padding: 0 8px;
  font-size: 12px;
  color: var(--grayA11);
  border-radius: 4px;
  margin: 4px 0 4px 4px;
  user-select: none;
  text-transform: capitalize;
  font-weight: 500;
}
.dialog [cmdk-item] {
  content-visibility: auto;
  cursor: pointer;
  /* height: 48px; */
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 16px;
  color: var(--gray11);
  user-select: none;
  will-change: background, color;
  transition: all 150ms ease;
  transition-property: none;
}
.dialog [cmdk-item][aria-selected='true'] {
  background: var(--grayA3);
  color: var(--gray12);
}
.dialog [cmdk-item][aria-disabled='true'] {
  color: var(--gray8);
  cursor: not-allowed;
}
.dialog [cmdk-item]:active {
  transition-property: background;
  background: var(--gray4);
}
.dialog [cmdk-item] + [cmdk-item] {
  margin-top: 4px;
}
.dialog [cmdk-item] svg {
  width: 18px;
  height: 18px;
}
.dialog [cmdk-list] {
  height: min(330px, var(--cmdk-list-height));
  max-height: 400px;
  overflow: auto;
  overscroll-behavior: contain;
  transition: 100ms ease;
  transition-property: height;
}
.dialog [cmdk-vercel-shortcuts] {
  display: flex;
  margin-left: auto;
  gap: 8px;
}
.dialog [cmdk-vercel-shortcuts] kbd {
  font-family: var(--font-sans);
  font-size: 12px;
  min-width: 20px;
  padding: 4px;
  height: 20px;
  border-radius: 4px;
  color: var(--gray11);
  background: var(--gray4);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}
.dialog [cmdk-separator] {
  height: 1px;
  width: 100%;
  background: var(--gray5);
  margin: 4px 0;
}
.dialog *:not([hidden]) + [cmdk-group] {
  margin-top: 8px;
}
.dialog [cmdk-group-heading] {
  user-select: none;
  font-size: var(--font-size-0);
  color: var(--gray11);
  padding: 0 8px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.dialog [cmdk-empty] {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  white-space: pre-wrap;
  color: var(--gray11);
}
